.horizontal-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.vertical-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.pfp {
    border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: 10%;
    
}

.project-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: 100%;
}


.spacer {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 550px;
}


@media screen and (max-width: 780px) {
    .horizontal-container {
        flex-direction: column;
    }

    .pfp {
        width: 300px;
        margin-right: auto;
    }

    .project-container {
        flex-direction: column;
        width: 100%;
    }

    .spacer {
        height: 300px;
    }
}

