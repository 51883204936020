.windowsTabsBody {
    background-color: #c0c0c0;
    position: relative;
    z-index: 1;
    min-height: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 24px 0;
    gap: 8px;
    box-shadow: inset -2px -2px 0px #0a0a0a, inset 2px 2px 0px #ffffff,
      inset -4px -4px 0px #808080, inset 4px 4px 0px #dfdfdf;
  }