@font-face {
    font-family: "MS Sans Serif";
    src: url("../../../fonts/W95FA.otf") format("opentype");
  }
  @font-face {
    font-family: "MS Sans Serif Bold";
    src: url(../../../fonts/MS\ Sans\ Serif\ Bold.ttf) format("truetype");
  }
  body {
    font-size: 1.2rem;
    font-family: "MS Sans Serif", sans-serif;
  }
  a {
    color: #222222;
  }
  p {
    margin: 0 0 1rem 0;
  }
  /* HEADINGS */
  /* H1 */
  .windowsH1 {
    font-size: 6rem;
  }
  .windowsH1.h1Bold {
    font-size: 4.7rem;
    font-family: "MS Sans Serif Bold", sans-serif;
  }
  /* H2 */
  .windowsH2 {
    font-size: 3.75rem;
  }
  .windowsH2.h2Bold {
    font-size: 2.95rem;
    font-family: "MS Sans Serif Bold", sans-serif;
  }
  /* H3 */
  .windowsH3 {
    font-size: 3rem;
  }
  .windowsH3.h3Bold {
    font-size: 2.36rem;
    font-family: "MS Sans Serif Bold", sans-serif;
  }
  /* H4 */
  .windowsH4 {
    font-size: 2.2rem;
  }
  .windowsH4.h4Bold {
    font-size: 1.73rem;
    font-family: "MS Sans Serif Bold", sans-serif;
  }
  /* H5 */
  .windowsH5 {
    font-size: 1.5rem;
  }
  .windowsH5.h5Bold {
    font-size: 1.18rem;
    font-family: "MS Sans Serif Bold", sans-serif;
  }
  /* H6 */
  .windowsH6 {
    font-size: 1.25rem;
  }
  .windowsH6.h6Bold {
    font-size: 0.98rem;
    font-family: "MS Sans Serif Bold", sans-serif;
  }
  
  /* p */
  .windowsP {
    font-size: 1.2rem;
  }
  
  .pBold {
    font-size: 0.9rem;
    font-family: "MS Sans Serif Bold", sans-serif;
  }
  
  /* white */
  .whiteFont {
    color: #fff;
  }
  
  .windowsPre {
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: inherit;
    line-height: 25px;
    font-size: 1.2rem;
  }