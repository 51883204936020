.windowsModal {
    width: 642px;
    min-height: 150px;
    padding: 4px 4px 24px;
    background-color: #c0c0c0;
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%);
    z-index: 3;
    box-shadow: inset -2px -2px 0px #0a0a0a, inset 2px 2px 0px #dfdfdf,
      inset -3px -3px 0px #808080, inset 3px 3px 0px #ffffff;
  }
  .windowsModalContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    height: 100%;
    padding: 24px;
  }
  
  .windowsModal--overlay {
    z-index: 2;
    display: inline-block;
    width: 200vw;
    height: 200vh;
    position: fixed;
    top: -100vh;
    left: -100vw;
    background-color: rgba(0, 0, 0, 0.3);
  }