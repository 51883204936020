/* Footer.css */
.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 1rem 0;
    width: 100%;
    position: relative;
    bottom: 0;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1;
  }
  
  @media (max-width: 768px) {
    .footer {
      padding: 0.5rem 0;
    }
  }
  
  .footer-a {
    color: white;
  }